import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Navigation } from 'swiper/modules';
import './League.css';
import Loading from '../components/Loading';

const League = () => {
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const userId = localStorage.getItem("userId");
    const [user, setUser] = useState({});
    const [initialSlide, setInitialSlide] = useState(0);

    useEffect(() => {
        if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.BackButton.show();
            window.Telegram.WebApp.BackButton.onClick(() => {
                window.history.back(); // Navigate back in the browser history
            });
        }

        const fetchUserData = async () => {
            try {
                const { data } = await axios.get(`/api/users/${userId}`);
                setUser(data);
            } catch (err) {
                console.error('Failed to fetch user details');
            }
        };

        const fetchTasks = async () => {
            try {
                const { data } = await axios.get(`/api/tasks/Leagues?userId=${userId}`);
                console.log('leagueTasks', data);
                setTasks(data);
                setLoading(false);
            } catch (err) {
                console.error('Failed to fetch tasks', err);
                setLoading(false);
            }
        };

        fetchUserData();
        fetchTasks();
    }, [userId]);

    useEffect(() => {
        if (tasks.length > 0 && user.level) {
            const userLevelIndex = tasks.findIndex(task => task.title === user.level);
            if (userLevelIndex !== -1 && userLevelIndex < tasks.length - 1) {
                setInitialSlide(userLevelIndex + 1); // Set to the next level
            }
        }
    }, [tasks, user.level]);

    return (
        <div className="league">
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Swiper
                        navigation
                        modules={[Navigation]}
                        slidesPerView={1}
                        initialSlide={initialSlide}
                        className="league-swiper"
                    >
                        {tasks.map((task, index) => {
                            const fromNumber = index > 0 ? tasks[index - 1].league : 1;
                            const userLevelIndex = tasks.findIndex(task => task.title === user.level);
                            const isNextLevel = userLevelIndex !== -1 && index === userLevelIndex + 1;
                            const nextTask = tasks[index + 1];

                            return (
                                <SwiperSlide
                                    key={index}
                                    className={`league-container ${isNextLevel ? 'active-league' : ''}`}
                                >
                                    <h2>{task.title} League</h2>
                                    <p className="subtitle">Your number of shares determines the league you enter.</p>
                                    <img src={task.icon} alt={`${task.title} icon`} />
                                    {isNextLevel ? (
                                        <>
                                            <p><strong>{user.coins.toLocaleString('en-US')} / {task.league.toLocaleString('en-US')}</strong></p>
                                            <div className="progress-bar">
                                                <div
                                                    className="progress"
                                                    style={{ width: `${(user.coins / task.league) * 100}%` }}
                                                ></div>
                                            </div>
                                        </>
                                    ) : (
                                        <p><strong>From {fromNumber.toLocaleString('en-US')}</strong></p>
                                    )}
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </>
            )}
        </div>
    );
};

export default League;
