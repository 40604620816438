import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import './TaskDetail.css'; // Ensure CSS matches your design
import {toast} from 'react-toastify';
import Loading from '../components/Loading'; // Import the Loading component

const TaskDetail = () => {
    const {taskId} = useParams();
    const userId = localStorage.getItem("userId");
    const [task, setTask] = useState({});
    const [taskItems, setTaskItems] = useState([]);
    const [userTask, setUserTask] = useState([]);
    const [loading, setLoading] = useState(true);
    const [missionStarted, setMissionStarted] = useState(false);
    const [missionCompleted, setMissionCompleted] = useState(false);
    const [inputValues, setInputValues] = useState({});
    const [allTasksCompleted, setAllTasksCompleted] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [errors, setErrors] = useState({}); // State to track errors for each task item

    useEffect(() => {
        if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.BackButton.show();
            window.Telegram.WebApp.BackButton.onClick(() => {
                window.history.back(); // Navigate back in the browser history
            });
        }
        const fetchTaskDetails = async () => {
            try {
                const {data} = await axios.get(`/api/tasks/details/${taskId}`, {
                    params: {userId}  // Sending userId as a query parameter
                });
                setTask(data.task);
                setTaskItems(data.taskItems);
                if (data.userTask != null) {
                    setUserTask(data.userTask);
                    setMissionStarted(true)
                    if (data.userTask.status === "completed") {
                        setMissionCompleted(true)
                    } else {
                        setMissionCompleted(false)
                    }
                }
                checkAllTasksCompleted(data.taskItems);
            } catch (err) {
                console.error('Failed to fetch task details', err);
            } finally {
                setLoading(false);
            }
        };

        fetchTaskDetails();
    }, [taskId]);

    useEffect(() => {
        checkAllTasksCompleted(taskItems);
    }, [taskItems]);

    const checkAllTasksCompleted = (items) => {
        const allCompleted = items.every(item => item.itemStatus && item.itemStatus.status === "done");
        setAllTasksCompleted(allCompleted);
    };

    const handleStartMission = async () => {
        try {
            await axios.post('/api/tasks/start', {userId, taskId});
            setMissionStarted(true);
        } catch (err) {
            console.error('Failed to start mission', err);
        }
    };

    const handleFinishMission = async () => {
        try {
            await axios.post('/api/tasks/complete', {userId, taskId});
            setMissionCompleted(true)
            closeModal()
        } catch (err) {
            console.error('Failed to complete mission', err);
        }
    };

    const handleInputChange = (e, taskItemId) => {
        setInputValues({
            ...inputValues,
            [taskItemId]: e.target.value
        });
    };

    const handleSubmit = async (taskItemId, code, time, watchedAt) => {
        const watchedAtDate = new Date(watchedAt);
        const watchedAtTime = watchedAtDate.getTime();
        const taskTime = time * 1000; // Convert seconds to milliseconds
        const targetTime = new Date(watchedAtTime + taskTime);
        const currentTime = new Date();
        if (currentTime > targetTime) {
            if (inputValues[taskItemId] === code) {
                try {
                    const response = await axios.post('/api/tasks/check-submit', {userId, taskItemId});
                    if (response.data && response.data.status === "done") {
                        setTaskItems(prevItems => prevItems.map(item =>
                            item._id === taskItemId ? {...item, itemStatus: {status: "done"}} : item
                        ));
                        setErrors(prevErrors => ({...prevErrors, [taskItemId]: ''})); // Clear error
                        toast.success('You task is done.');
                    } else {
                        setErrors(prevErrors => ({...prevErrors, [taskItemId]: 'It seems you did not join yet!'}));
                    }

                } catch (err) {
                    console.error('Failed to complete task item', err);
                    setErrors(prevErrors => ({...prevErrors, [taskItemId]: 'Failed to complete task item'}));
                }
            } else {
                setErrors(prevErrors => ({...prevErrors, [taskItemId]: 'Code is not valid!'}));
            }
        } else {
            setErrors(prevErrors => ({...prevErrors, [taskItemId]: 'It seems you did not visit the Link yet!'}));
        }
    };

    const handleJoinCheck = async (taskItemId) => {
        try {
            const response = await axios.post('/api/tasks/check-join', {userId, taskItemId});
            if (response.data && response.data.status === "done") {
                setTaskItems(prevItems => prevItems.map(item =>
                    item._id === taskItemId ? {...item, itemStatus: {status: "done"}} : item
                ));
                setErrors(prevErrors => ({...prevErrors, [taskItemId]: ''})); // Clear error
                toast.success('You task is done.');
            } else {
                setErrors(prevErrors => ({...prevErrors, [taskItemId]: 'It seems you did not join yet!'}));
            }
        } catch (err) {
            console.error('Failed to check join status', err);
            setErrors(prevErrors => ({...prevErrors, [taskItemId]: 'Failed to check join status'}));
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleWatch = async (taskId, taskItemId, link) => {
        window.open(link, '_blank');
        try {
            await axios.post('/api/tasks/watch', {userId, taskId, taskItemId});
            setTaskItems(prevItems => prevItems.map(item =>
                item._id === taskItemId ? {...item, itemStatus: {status: "watched"}} : item
            ));
        } catch (err) {
            console.error('Failed to update task item status', err);
        }
    };

    if (loading) {
        return <Loading/>;
    }

    return (
        <div className="task-detail">
            <h1>{task.title}</h1>
            <p>{task.description}</p>
            <div className="reward-box">
                <span className="coin-icon">
                    <img src="/icons/coin.png" width="45px" alt='Coin'/>
                </span>
                <div className="reward">
                    <span className="reward-text">
                        Reward
                    </span>
                    <span className="reward-coin">
                        {task.coins.toLocaleString('en-US')}
                    </span>
                </div>
            </div>
            {!missionStarted && !missionCompleted && (
                <button className="start-mission-button" onClick={handleStartMission}>Start Mission</button>
            )}

            {missionCompleted && (
                <p className="green">Mission Completed!</p>
            )}
            <h3>Your tasks</h3>
            {taskItems.map((item) => (
                <div key={item._id} className="task-item allow-touch">
                    <div className="task-title">{item.title}
                        {errors[item._id] && <p className="error-text">{errors[item._id]}</p>}
                    </div>
                    {!item.isSocial ? (
                        <>
                            {missionStarted && !missionCompleted && (
                                item.itemStatus && item.itemStatus.status === "done" ? (
                                    <button disabled>Done</button>
                                ) : (
                                    <button onClick={() => handleWatch(item.taskId, item._id, item.link)}>
                                        Watch
                                    </button>
                                )

                            )}
                            {item.itemStatus != null && item.itemStatus.status === "watched" && (
                                <>
                                    <div className="separator"></div>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            placeholder="Provide an answer"
                                            value={inputValues[item._id] || ''}
                                            onChange={(e) => handleInputChange(e, item._id)}
                                        />
                                        <button
                                            onClick={() => handleSubmit(item._id, item.code, item.time, item.watchedAt)}>Submit
                                        </button>
                                    </div>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {missionStarted && !missionCompleted && (
                                item.itemStatus && item.itemStatus.status === "watched" && item.isSocial ? (
                                    <button onClick={() => handleJoinCheck(item._id)}>Check</button>
                                ) : (
                                    <button
                                        onClick={() => {
                                            if (!item.itemStatus || item.itemStatus.status !== "done") {
                                                handleWatch(item.taskId, item._id, item.link);
                                            }
                                        }}>
                                        {item.itemStatus && item.itemStatus.status === "done" ? "Done" : (item.isSocial === 1 ? 'Join' : 'Watch')}
                                    </button>
                                )
                            )}

                        </>
                    )}
                </div>
            ))}
            {
                missionStarted && !missionCompleted && (
                    <button className={`finish-mission-button ${!allTasksCompleted ? 'disabled' : ''}`}
                            onClick={() => {
                                if (allTasksCompleted) {
                                    setIsModalOpen(true);
                                }
                            }}
                    >Finish Mission</button>
                )
            }

            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <button className="close-button" onClick={closeModal}>×</button>
                        <img src="/icons/trophy.png" alt={task.title} className="modal-image"/>
                        <h2>Congratulations</h2>
                        <p>you've successfully completed the mission</p>
                        <div className="modal-fee">
                            <img src="/icons/coin.png" width="25px" alt="Coin"/>
                            <span>{task.coins.toLocaleString('en-US')}</span>
                        </div>
                        <button className="modal-button claim"
                                onClick={() => {
                                    handleFinishMission();
                                }}
                        >
                            Claim
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TaskDetail;
