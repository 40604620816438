import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import './Task.css'; // Ensure CSS matches your design
import Loading from '../components/Loading'; // Import the Loading component

const Task = () => {
    const userId = localStorage.getItem("userId");
    const [tasks, setTasks] = useState({Cinema: [], Special: [], Leagues: [], Ref: []});
    const [selectedTab, setSelectedTab] = useState('Special');
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(true);
    const [referredCount, setReferredCount] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.BackButton.show();
            window.Telegram.WebApp.BackButton.onClick(() => {
                window.history.back(); // Navigate back in the browser history
            });
        }
        const fetchUser = async () => {
            try {
                const {data} = await axios.get(`/api/users/${userId}`);
                setUser(data);
                fetchReferralData(data.referralCode)
            } catch (err) {
                console.error('Failed to fetch user data', err);
            }
        };

        fetchUser();
    }, [userId]);

    useEffect(() => {
        const fetchTasks = async () => {
            try {
                const cinemaTasks = await axios.get(`/api/tasks/Cinema?userId=${userId}`);
                const specialTasks = await axios.get(`/api/tasks/Special?userId=${userId}`);
                const leagueTasks = await axios.get(`/api/tasks/Leagues?userId=${userId}`);
                const refTasks = await axios.get(`/api/tasks/Ref?userId=${userId}`);
                console.log('leagueTasks', leagueTasks)
                setTasks({
                    Cinema: cinemaTasks.data,
                    Special: specialTasks.data,
                    Leagues: leagueTasks.data,
                    Ref: refTasks.data
                });
            } catch (err) {
                console.error('Failed to fetch tasks', err);
            } finally {
                setLoading(false);
            }
        };

        fetchTasks();
    }, []);

    const fetchReferralData = async (referralCode) => {
        try {
            const { data } = await axios.get(`/api/referrals/${referralCode}`);
            setReferredCount(data.count);
        } catch (err) {
            toast.error('Failed to fetch referral details');
        }
    };

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };

    const handleTaskClick = (task, tab) => {
        if (tab === 'Cinema' || tab === 'Special') {
            navigate(`/tasks/${task._id}`);
        }
    };

    const handleClaim = async (task, tab) => {
        try {
            const response = await axios.post('/api/tasks/claim', {userId, taskId: task._id, tab, referredCount});
            if (response.status === 200) {
                const {coins, referredCount} = response.data.user;
                setUser({...user, coins, referredCount});
                setTasks(prevTasks => ({
                    ...prevTasks,
                    [tab]: prevTasks[tab].map(t => t._id === task._id ? { ...t, userTask: { ...t.userTask, status: 'completed' } } : t)
                }));
                toast.success("You get the reward.")
            } else {
                toast.error("Something went wrong")
            }
        } catch (err) {
            toast.error("You Already claimed.")
        }
    };

    const formatNumber = (number) => {
        if (number >= 1000000000) {
            return (number / 1000000000).toFixed(3) + ' B'; // Convert to billions and add 'T'
        } else if (number >= 1000000) {
            return (number / 1000000).toFixed(3) + ' M';
        }
        return new Intl.NumberFormat('en-US').format(number); // Format with thousand separators
    };

    const renderTaskItem = (task, tab) => {
        console.log("user.referredCount",referredCount)
        console.log('task', task.userTask)
        const isCompleted = !!(task.userTask && task.userTask.status === "completed");
        const canClaim = (tab === 'Leagues' && user.coins >= task.league) ||
            (tab === 'Ref' && referredCount >= task.ref);

        return (
            <div key={task.id} className="task-item" onClick={() => handleTaskClick(task, tab)}>
                <div className="task-icon">
                    <img width="40" src={task.icon} alt={`${task.title} icon`}/>
                </div>
                <div className="task-details text-black">
                    <div className="task-title">{task.title}</div>
                    <div className="task-coins">{task.coins.toLocaleString('en-US')}</div>
                </div>
                <div className="task-action">
                    {tab === 'Special' || tab === 'Cinema' ? (
                        isCompleted ? <span className="task-completed green">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-check"
                            >
                                <path d="M20 6L9 17l-5-5"/>
                            </svg>
                        </span> : <span className="task-arrow">
                            <svg
                                width="30px" height="30px" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9 5L14.15 10C14.4237 10.2563 14.6419 10.5659 14.791 10.9099C14.9402 11.2539 15.0171 11.625 15.0171 12C15.0171 12.375 14.9402 12.7458 14.791 13.0898C14.6419 13.4339 14.4237 13.7437 14.15 14L9 19"
                                    stroke="#ffffff" opacity="0.4" strokeWidth="1.5" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                            </svg>
                        </span>
                    ) : (tab === 'Leagues' || tab === 'Ref') && (
                        isCompleted ? <span className="task-completed green">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-check"
                            >
                                <path d="M20 6L9 17l-5-5"/>
                            </svg>
                        </span> : <button disabled={!canClaim} className={`claim-button ${!canClaim ? 'disabled' : ''}`}
                                          onClick={() => handleClaim(task, tab)}>
                            {canClaim ? 'Claim' : 'Claim'}
                        </button>
                    )}
                </div>
            </div>
        );
    };

    const renderProgressBar = (tab) => {
        if (tab !== 'Leagues' && tab !== 'Ref') return null;

        const totalRequiredCoins = tasks[tab].reduce((sum, task) => sum + task.coins, 0);
        const progress = (user.coins / totalRequiredCoins) * 100;

        return (
            <div className="progress-bar-container">
                <div className="progress-bar">
                    <div className="progress" style={{width: `${Math.min(progress, 100)}%`}}></div>
                </div>
            </div>
        );
    };

    return (
        <div className="task">
            {loading ? (
                <Loading/>
            ) : (
                <>
                    <div className="header">
                        <div className="user-info">
                            <div className="coins-display">
                                <span className="coin-icon">
                                    <img src="/icons/coin.png" width="35px" alt='Coin'/>
                                </span>
                                <span className="coin-text">
                                    {formatNumber(user.coins)}
                                </span>
                            </div>
                            <div className="rank">
                                <span className="rank-icon">
                                    <img src={`/icons/leagues/${user.level.toLowerCase()}.png`} width="30px" alt='Coin'/>
                                </span>
                                <span className="rank-text">
                                    {user.level}
                                </span>
                            </div>
                        </div>
                        <div className="separator"></div>
                        <div className="tab-bar">
                            <div className={`tab ${selectedTab === 'Special' ? 'active' : ''}`}
                                 onClick={() => handleTabClick('Special')}>
                                Special
                            </div>
                            <div className={`tab ${selectedTab === 'Cinema' ? 'active' : ''}`}
                                 onClick={() => handleTabClick('Cinema')}>
                                Cinema
                            </div>
                            <div className={`tab ${selectedTab === 'Leagues' ? 'active' : ''}`}
                                 onClick={() => handleTabClick('Leagues')}>
                                Leagues
                            </div>
                            <div className={`tab ${selectedTab === 'Ref' ? 'active' : ''}`}
                                 onClick={() => handleTabClick('Ref')}>
                                Ref
                            </div>
                        </div>
                    </div>
                    <div className="tab-content allow-touch">
                        {tasks[selectedTab].map((task) => renderTaskItem(task, selectedTab))}
                        {renderProgressBar(selectedTab)}
                    </div>

                </>
            )}
        </div>
    );
};

export default Task;
