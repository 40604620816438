// Stats.js
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Stats.css';
import Loading from '../components/Loading'; // Import the Loading component

const Stats = () => {
    const [stats, setStatsData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.BackButton.show();
            window.Telegram.WebApp.BackButton.onClick(() => {
                window.history.back(); // Navigate back in the browser history
            });
        }
        const fetchStatsData = async () => {
            try {
                const {data} = await axios.get('/api/stats');
                setStatsData(data);
            } catch (err) {
                toast.error('Failed to fetch stats data');
            } finally {
                setLoading(false);
            }
        };

        fetchStatsData();
    }, []);

    const formatNumber = (number) => {
        if (number >= 1000000000) {
            return (number / 1000000000).toFixed(3) + ' B'; // Convert to billions and add 'T'
        } else if (number >= 1000000)  {
            return (number / 1000000).toFixed(3) + ' M';
        }
        return new Intl.NumberFormat('en-US').format(Math.round(number)); // Format with thousand separators
    };

    return (
        <div className="stats">
            {loading ? (
                <Loading/> // Show Loading component when loading
            ) : (
                <>
                    <div className="header">
                        <span className="title">Total Share Balance:</span>
                        <div className="total-count">
                            <span className="coin-icon">
                                    <img src="/icons/coin.png" width="35px" alt='Coin'/>
                                </span>
                            <span className="coin-text">
                                    {formatNumber(Math.round(stats.totalTouches))}
                                </span>
                        </div>
                        <div className="separator"></div>
                    </div>
                    <div>
                        <span className="title">Total Touches:</span>
                        <div className="count">
                            <span className="coin-text">
                                    {formatNumber(stats.totalTouches/ 13)}
                                </span>
                        </div>

                    </div>
                    <div>
                        <span className="title">Total Players:</span>
                        <div className="count">
                            <span className="coin-text">
                                    {stats.totalPlayers}
                                </span>
                        </div>
                    </div>
                    <div>
                        <span className="title">Daily Users:</span>
                        <div className="count">
                            <span className="coin-text">
                                    {stats.dailyUsers}
                                </span>
                        </div>
                    </div>
                    <div>
                        <span className="title">Online Players:</span>
                        <div className="count">
                            <span className="coin-text">
                                    {stats.onlinePlayers}
                                </span>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Stats;
