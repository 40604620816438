import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Leaderboard.css'; // Add corresponding CSS
import Loading from '../components/Loading'; // Adjust the import path if needed


const Leaderboard = () => {
    const [leaders, setLeaders] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchLeaders = async () => {
            try {
                const { data } = await axios.get('/api/users/leaderboard');
                const usersWithAvatars = await Promise.all(
                    data.map(async (user) => {
                        try {
                            const resAvatar = await axios.get(`/api/users/avatar/${user.userId}`, {
                                responseType: 'blob',
                            });
                            if (resAvatar.data) {
                                const objectUrl = URL.createObjectURL(resAvatar.data);
                                return { ...user, avatar: objectUrl };
                            }
                        } catch (err) {
                            console.warn("avatar eroor")
                        }
                        return user;
                    })
                );
                setLeaders(usersWithAvatars);
            } catch (error) {
                console.error('Error fetching leaderboard data', error);
            } finally {
                setLoading(false);
            }
        };

        fetchLeaders();
    }, []);

    const formatNumber = (number) => {
        if (number >= 1000000000) {
            return (number / 1000000000).toFixed(2) + ' B'; // Convert to billions and add 'T'
        } else if (number >= 1000000)  {
            return (number / 1000000).toFixed(2) + ' M';
        } else if (number >= 1000) {
            return (number / 1000).toFixed(1) + ' K';
        }
        return new Intl.NumberFormat('en-US').format(number); // Format with thousand separators
    };

    return (
        <div className="leaderboard">
            {loading ? (
                <Loading /> // Show Loading component when loading
            ) : (
                <div className="frens-main allow-touch">
                    <div className="frens-count">
                        <span>Leader Board</span>
                    </div>
                    <div className="frens-list allow-touch">
                        {leaders.length > 0 ? (
                            <div className="frens-list-box">
                                {leaders.map(user => (
                                    <div className="frens-data" key={user.userId}>
                                        <div className="frens-profile">
                                            <div className="frens-profile-photo">
                                                <img src={user.avatar} width="56" alt=""/>
                                            </div>
                                            <div className="frens-profile-data">
                                                <h4 className="white">{user.first_name.slice(0, 16)}</h4>
                                                <div>
                                                    <img src={`/icons/leagues/${user.level.toLowerCase()}.png`} width="15px" alt=""/>
                                                    <span className="white">{user.level}</span>
                                                    <span>•</span>
                                                    {/*<img src="/icons/coin.png" width="15px" alt=""/>*/}
                                                    {/*<span>{formatNumber(user.coins)}</span>*/}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="frens-data-count bold">
                                            <span>+{formatNumber(user.coins)}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="frens-data">
                                <div className="frens-profile">
                                    <div className="frens-profile-data white">
                                        <h4>There is no one in your app 😢</h4>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Leaderboard;
