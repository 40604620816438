import React, {useState, useEffect, useMemo, useCallback} from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {Connection, PublicKey, Transaction, SystemProgram, clusterApiUrl, LAMPORTS_PER_SOL} from '@solana/web3.js';
import {useWallet, WalletProvider, ConnectionProvider, useConnection} from '@solana/wallet-adapter-react';
import {PhantomWalletAdapter} from '@solana/wallet-adapter-wallets';
import {UnsafeBurnerWalletAdapter} from '@solana/wallet-adapter-wallets';
import {WalletAdapterNetwork} from '@solana/wallet-adapter-base';
import {WalletModalProvider, WalletMultiButton, WalletDisconnectButton} from '@solana/wallet-adapter-react-ui';
import {getAssociatedTokenAddress, createTransferInstruction, createAssociatedTokenAccountInstruction} from '@solana/spl-token';
import { toast, ToastContainer } from 'react-toastify'; // Import react-toastify
import 'react-toastify/dist/ReactToastify.css';
import '@solana/wallet-adapter-react-ui/styles.css';
import './Pay.css';

const Pay = () => {
    const { userId } = useParams();
    const [connected, setConnected] = useState(false);
    const [balance, setBalance] = useState(0);
    const wallet = useWallet();
    const network = WalletAdapterNetwork.Devnet;
    const [loading, setLoading] = useState(false);
    const [timeLeft, setTimeLeft] = useState(0); // Initialize with 0
    const [isCounting, setIsCounting] = useState(false);

    const {connection} = useConnection();
    const {publicKey, sendTransaction} = useWallet();

    const CLX_MINT_ADDRESS = '3Q1kMDgekoMy69vaZykgfCPeuDUEbJSQSzvcK7C44EFw';
    const DESTINATION_WALLET = '6y6UNAm9BEQkSnmXjnBJPWfu68Lhg3iSE7YwTurm1PUL';


    useEffect(() => {
        localStorage.setItem("userId", userId);
        let timer;
        if (isCounting && timeLeft > 0) {
            timer = setInterval(() => {
                setTimeLeft((prev) => prev - 1);
            }, 1000);
        } else if (timeLeft === 0) {
            setIsCounting(false);
        }

        return () => clearInterval(timer); // Cleanup interval
    }, [timeLeft, isCounting]);


    const onClick = useCallback(async () => {
        setLoading(true);
        setTimeLeft(120);
        setIsCounting(true);

        if (!publicKey) {
            toast.error('Wallet not connected');
            return;
        }

        try {
            const amount = 8130 * 1e9;
            const mintAddress = new PublicKey(CLX_MINT_ADDRESS);
            const destinationAddress = new PublicKey(DESTINATION_WALLET);

            const fromTokenAccount = await getAssociatedTokenAddress(
                mintAddress,
                publicKey
            );

            const toTokenAccount = await getAssociatedTokenAddress(
                mintAddress,
                destinationAddress
            );

            const instructions = [];

            const receiverAccountInfo = await connection.getAccountInfo(toTokenAccount);
            if (!receiverAccountInfo) {
                instructions.push(
                    createAssociatedTokenAccountInstruction(
                        publicKey,
                        toTokenAccount,
                        destinationAddress,
                        mintAddress
                    )
                );
            }

            instructions.push(
                createTransferInstruction(
                    fromTokenAccount,
                    toTokenAccount,
                    publicKey,
                    amount,
                    []
                )
            );

            const transaction = new Transaction().add(...instructions);
            const latestBlockhash = await connection.getLatestBlockhash();

            const signature = await sendTransaction(transaction, connection, {
                skipPreflight: false,
                preflightCommitment: 'confirmed',
                blockhash: latestBlockhash.blockhash,
                lastValidBlockHeight: latestBlockhash.lastValidBlockHeight
            });

            await connection.confirmTransaction(signature, 'confirmed');

            toast.success('Transaction successful!');
            handlePurchase();

            setTimeout(() => {
                redirectToTelegram();
            }, 2000); // Wait for 2 seconds before redirecting

        } catch (error) {
            console.error('Transaction error:', error);
            toast.error('Transaction failed!');
            setTimeout(() => {
                redirectToTelegram();
            }, 2000);
        } finally {
            setLoading(false);
        }
    }, [publicKey, sendTransaction, connection]);

    const handlePurchase = async () => {
        try {
            const response = await axios.put(`/api/users`, {
                userId,
                boosterType: 'Tap Bot',
                bot: true
            });

            if (response.data) {
                toast.success(`Successfully purchased Bot!`);
            }
        } catch (error) {
            console.error('Error purchasing booster:', error);
            toast.error('An error occurred during the purchase');
        }
    };

    const redirectToTelegram = () => {
        const telegram = window.Telegram.WebApp;
        telegram.ready();
        const gameUrl = 'https://t.me/cryptoluxebot_bot'; // Replace with the actual URL
        window.location.href = gameUrl;
    };

    useEffect(() => {
        if (wallet.connected) {
            setConnected(true);
            // fetchBalance();
        }
    }, [wallet.connected]);

    const fetchBalance = async () => {
        if (!wallet.publicKey) return;
        const connection = new Connection(clusterApiUrl('mainnet-beta'));
        const balance = await connection.getBalance(wallet.publicKey);
        if (!balance){
            setBalance(balance / LAMPORTS_PER_SOL);
        }
    };

    // You can also provide a custom RPC endpoint.
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);

    const wallets = useMemo(
        () => [
            /**
             * Wallets that implement either of these standards will be available automatically.
             *
             *   - Solana Mobile Stack Mobile Wallet Adapter Protocol
             *     (https://github.com/solana-mobile/mobile-wallet-adapter)
             *   - Solana Wallet Standard
             *     (https://github.com/anza-xyz/wallet-standard)
             *
             * If you wish to support a wallet that supports neither of those standards,
             * instantiate its legacy wallet adapter here. Common legacy adapters can be found
             * in the npm package `@solana/wallet-adapter-wallets`.
             */
            new UnsafeBurnerWalletAdapter(),
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [network]
    );

    return (
        <>
            <div className="pay_container white">
                <div className="pay">
                    <div>
                        <img width="200px" src="../images/solana.png" className="logo" alt="Solana"/>
                    </div>
                    <div className="">
                        <h1 className="white">Send Token</h1>
                        <span className="title gray">Send Token to enable Bot {userId}</span>
                        <div className="separator"></div>
                    </div>

                    <div className="card">
                        <WalletModalProvider>
                            <WalletMultiButton/>
                            {/*<WalletDisconnectButton/>*/}
                            {connected && (
                                <div className="address">
                                    <p><b>Status: </b>{connected ? "Connected" : "Disconnected"}</p>
                                    <p>Send 8130 CLX to get BOT enable in one Week</p>
                                    <button className={`submit-button ${!publicKey || loading ? 'disabled-button' : ''}`} onClick={onClick} disabled={!publicKey || loading}>
                                        {!publicKey || loading ? 'Waiting '+timeLeft : 'Send'}
                                    </button>
                                </div>
                            )}
                        </WalletModalProvider>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Pay;

