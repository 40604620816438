import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Boost.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import Loading from '../components/Loading';
import { useNavigate } from 'react-router-dom';

const Boost = () => {
    const userId = localStorage.getItem("userId");
    const [energy, setEnergy] = useState(0);
    const [coins, setCoins] = useState(0);
    const [tapLevel, setTapLevel] = useState(1);
    const [energyLevel, setEnergyLevel] = useState(1);
    const [speedLevel, setSpeedLevel] = useState(1);
    const [bot, setBot] = useState(false);
    const [skin, setSkin] = useState('Basic');
    const [skins, setSkins] = useState(['Basic']);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [loading, setLoading] = useState(true);
    const [turboUses, setTurboUses] = useState(3);
    const [fullEnergyUses, setFullEnergyUses] = useState(3);
    const [turboTimer, setTurboTimer] = useState("Loading");
    const [fullEnergyTimer, setFullEnergyTimer] = useState('Loading');
    const [intervals, setIntervals] = useState([]);
    const navigate = useNavigate();

    const tapEnergyPrices = {
        2: 200, 3: 500, 4: 1000, 5: 2000,
        6: 4000, 7: 8000, 8: 16000, 9: 25000,
        10: 50000, 11: 100000, 12: 200000, 13: 300000,
        14: 400000, 15: 500000, 16: 600000, 17: 700000,
        18: 800000, 19: 900000, 20: 1000000
    };

    const energySpeedPrices = {
        2: 2000, 3: 10000, 4: 100000, 5: 250000
    };

    useEffect(() => {
        if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.BackButton.show();
            window.Telegram.WebApp.BackButton.onClick(() => {
                window.history.back();
            });
        }

        const fetchUserData = async () => {
            try {
                const response = await axios.get(`/api/users/${userId}`);
                if (response.data) {
                    setEnergy(response.data.energy);
                    setCoins(response.data.coins);
                    setTurboUses(response.data.turboUses);
                    setFullEnergyUses(response.data.fullEnergyUses);
                    setTapLevel(response.data.tapLevel);
                    setSpeedLevel(response.data.speedLevel);
                    setEnergyLevel(response.data.energyLevel);
                    setBot(response.data.bot);
                    setSkin(response.data.skin);
                    setSkins(response.data.skins);
                    if (response.data.lastTurboUse) {
                        await startTimer(new Date(response.data.lastTurboUse), setTurboTimer, 6 * 60 * 60 * 1000);
                    }
                    if (response.data.lastFullEnergyUse) {
                        await startTimer(new Date(response.data.lastFullEnergyUse), setFullEnergyTimer, 6 * 60 * 60 * 1000);
                    }
                    // Calculate the time elapsed since lastTapUse and update energy
                    if (response.data.lastTapUse) {
                        const elapsedTime = Math.floor((Date.now() - new Date(response.data.lastTapUse).getTime()) / 1000);
                        const maxEnergy = tapLevel * 500;
                        const additionalEnergy = Math.min(elapsedTime, maxEnergy);
                        setEnergy(prevEnergy => Math.min(prevEnergy + additionalEnergy, maxEnergy));
                    }
                }
            } catch (error) {
                console.error('Failed to fetch user data', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();

        return () => {
            intervals.forEach(clearInterval);
        };
    }, [userId]);

    const startTimer = (lastUseTime, setTimer, duration) => {
        const targetTime = new Date(lastUseTime).getTime() + duration;
        const intervalId = setInterval(() => {
            const now = new Date().getTime();
            const distance = targetTime - now;

            if (distance < 0) {
                clearInterval(intervalId);
                setTimer("Available");
            } else {
                const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((distance % (1000 * 60)) / 1000);
                setTimer(`${hours}h ${minutes}m ${seconds}s`);
            }
        }, 1000);

        setIntervals(prevIntervals => [...prevIntervals, intervalId]);
        return intervalId;
    };

    const formatNumber = (number) => {
        if (number >= 1000000000) {
            return (number / 1000000000).toFixed(3) + ' B'; // Convert to billions and add 'T'
        } else if (number >= 1000000) {
            return (number / 1000000).toFixed(3) + ' M';
        }
        return new Intl.NumberFormat('en-US').format(number); // Format with thousand separators
    };

    const handleModalOpen = (type, cost = 0, icon, onClick = () => handlePurchase(type, cost)) => {
        let content = {};
        let hasEnoughCoins = false;
        switch (type) {
            case 'Multitap':
                const nextTapLevel = tapLevel + 1;
                cost = tapEnergyPrices[nextTapLevel] || cost;
                hasEnoughCoins = coins >= cost;
                content = {
                    image: `./icons/${icon}`,
                    title: type,
                    text: `Buy ${type}.`,
                    fee: `${cost.toLocaleString('en-US')} coins`,
                    status: !hasEnoughCoins || nextTapLevel > 20,
                    timer: nextTapLevel > 20 ? 'Max Purchased' : (hasEnoughCoins ? 'Buy' : 'Insufficient Balance!'),
                    onClick: hasEnoughCoins ? onClick : null
                };
                break;
            case 'Energy Limit':
                const nextEnergyLevel = energyLevel + 1;
                cost = tapEnergyPrices[nextEnergyLevel] || cost;
                hasEnoughCoins = coins >= cost;
                content = {
                    image: `./icons/${icon}`,
                    title: type,
                    text: `Buy ${type}.`,
                    fee: `${cost.toLocaleString('en-US')} coins`,
                    status: !hasEnoughCoins || nextEnergyLevel > 20,
                    timer: nextEnergyLevel > 20 ? 'Max Purchased' : (hasEnoughCoins ? 'Buy' : 'Insufficient Balance!'),
                    onClick: hasEnoughCoins ? onClick : null
                };
                break;
            case 'Recharging Speed':
                const nextSpeedLevel = speedLevel + 1;
                cost = energySpeedPrices[nextSpeedLevel] || cost;
                hasEnoughCoins = coins >= cost;
                content = {
                    image: `./icons/${icon}`,
                    title: type,
                    text: `Buy ${type}.`,
                    fee: `${cost.toLocaleString('en-US')} coins`,
                    status: !hasEnoughCoins || nextSpeedLevel > 5,
                    timer: nextSpeedLevel > 5 ? 'Max Purchased' : (hasEnoughCoins ? 'Buy' : 'Insufficient Balance!'),
                    onClick: hasEnoughCoins ? onClick : null
                };
                break;
            case 'Turbo':
                content = {
                    image: "./icons/rocket.png",
                    title: "Turbo",
                    text: "Activate Turbo mode.",
                    fee: "Free",
                    status: turboUses === 0,
                    timer: turboUses === 0 ? turboTimer : 'Get it!',
                    onClick: handleTurboClick
                };
                break;
            case 'FullEnergy':
                content = {
                    image: "./icons/thunder.png",
                    title: "Full Tank",
                    text: "Fill your energy to the max.",
                    fee: "Free",
                    status: fullEnergyUses === 0,
                    timer: fullEnergyUses === 0 ? fullEnergyTimer : 'Get it!',
                    onClick: handleFullEnergyClick
                };
                break;
            case 'Tap Bot':
                cost = 250000;
                hasEnoughCoins = coins >= cost;
                content = {
                    image: "./icons/robot_face.png",
                    title: "Tap Bot",
                    text: "Tap bot will tap when energy is full.\nMax bot work duration is 12 hours.",
                    fee: `${cost.toLocaleString('en-US')} coins`,
                    status: bot || !hasEnoughCoins,
                    timer: bot ? 'Activated' : (hasEnoughCoins ? 'Buy' : 'Insufficient Balance!'),
                    onClick: hasEnoughCoins ? onClick : null
                };
                break;
            default:
                hasEnoughCoins = coins >= cost;
                content = {
                    image: `./icons/${icon}`,
                    title: type,
                    text: `Buy ${type}.`,
                    fee: `${cost.toLocaleString('en-US')} coins`,
                    status: !hasEnoughCoins,
                    timer: hasEnoughCoins ? 'Buy' : 'Insufficient Balance!',
                    onClick: hasEnoughCoins ? onClick : null
                };
                break;
        }
        setModalContent(content);
        setIsModalOpen(true);
    };

    const handlePurchase = async (type, cost, skinTitle = null) => {
        setIsModalOpen(false);
        try {
            const response = await axios.put(`/api/users`, {
                userId,
                boosterType: type,
                cost: cost,
                coins: coins - cost,
            });

            if (response.data) {
                toast.success(`Successfully purchased ${type}!`);
                setCoins(response.data.coins);

                switch (type) {
                    case 'Multitap':
                        setTapLevel(response.data.tapLevel);
                        break;
                    case 'Energy Limit':
                        setEnergyLevel(response.data.energyLevel);
                        break;
                    case 'Recharging Speed':
                        setSpeedLevel(response.data.speedLevel);
                        break;
                    case 'Tap Bot':
                        setBot(true);
                        break;
                    default:
                        break;
                }

                if (skinTitle) {
                    setSkin(response.data.skin);
                    setSkins(response.data.skins);
                }
            } else {
                toast.error('Failed to complete the purchase');
            }
        } catch (error) {
            console.error('Error purchasing booster:', error);
            toast.error('An error occurred during the purchase');
        }
    };

    const handleTurboClick = async () => {
        setIsModalOpen(false);
        if (turboUses > 0 && (turboTimer === "Available" || isWithinOneMinute(new Date()))) {
            try {
                const response = await axios.put(`/api/users`, { userId, turbo: true });
                if (response.data) {
                    setTurboUses(turboUses - 1);
                    toast.success('Your Turbo is Now Activated!');

                    const activationTime = Date.now();
                    sessionStorage.setItem('turbo', JSON.stringify(true));
                    sessionStorage.setItem('turboActivationTime', JSON.stringify(activationTime));

                    navigate('/', {
                        replace: true
                    });
                } else {
                    toast.error('An error occurred while activating Turbo');
                }
            } catch (error) {
                console.error('Failed to activate Turbo', error);
                toast.error('An error occurred while activating Turbo');
            }
        } else {
            toast.error('No Turbo uses left or countdown period not yet over');
        }
    };


    const handleFullEnergyClick = async () => {
        setIsModalOpen(false);
        if (fullEnergyUses > 0 && (fullEnergyTimer === "Available" || isWithinOneMinute(new Date()))) {
            try {
                const response = await axios.put(`/api/users`, { userId, fullEnergy: true });
                if (response.data) {
                    setFullEnergyUses(fullEnergyUses - 1);
                    setEnergy(energyLevel * 500);
                    toast.success('Your Energy is Full Now!');
                    if (fullEnergyUses === 1) {
                        startTimer(new Date(), setFullEnergyTimer, 6 * 60 * 60 * 1000);
                    }
                    if (window.Telegram && window.Telegram.WebApp) {
                        window.Telegram.WebApp.HapticFeedback.impactOccurred('heavy');
                    }
                    navigate('/', { replace: true });
                } else {
                    toast.error('An error occurred while filling energy');
                    if (window.Telegram && window.Telegram.WebApp) {
                        window.Telegram.WebApp.HapticFeedback.impactOccurred('heavy');
                    }
                }
            } catch (error) {
                console.error('Failed to fill energy', error);
                toast.error('An error occurred while filling energy');
                if (window.Telegram && window.Telegram.WebApp) {
                    window.Telegram.WebApp.HapticFeedback.impactOccurred('heavy');
                }
            }
        } else {
            toast.error('No Full Energy uses left or countdown period not yet over');
            if (window.Telegram && window.Telegram.WebApp) {
                window.Telegram.WebApp.HapticFeedback.impactOccurred('heavy');
            }
        }
    };

    const isWithinOneMinute = (lastUseTime) => {
        const now = new Date().getTime();
        const oneMinute = 60 * 1000;
        return (now - lastUseTime.getTime()) < oneMinute;
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const splitIntoChunks = (array, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    };

    const renderSkins = () => {
        const skins = [
            { title: 'Basic', cost: 0, icon: 'golden.png', owned: true },
            { title: 'Silver', cost: 250000, icon: 'silver.png' },
            { title: 'Cookie', cost: 250000, icon: 'cookie.png' },
            { title: 'Soccer Ball', cost: 250000, icon: 'soccer-ball.png' },
            { title: 'Diamond', cost: 250000, icon: 'diamond.png' },
            { title: 'Diamond 2', cost: 250000, icon: 'diamond (1).png' },
            { title: '', cost: 0, icon: '' }
        ];

        const chunkedSkins = splitIntoChunks(skins, 3);

        return (
            <Swiper spaceBetween={0} slidesPerView={2}>
                {chunkedSkins.map((skinChunk, index) => (
                    <SwiperSlide key={index}>
                        <div className="skin-list">
                            {skinChunk.map((skin, idx) => (
                                <div className="skin-button m-b-1" style={{ opacity: skin.owned || skins.includes(skin.title) ? 0.5 : 1 }}
                                     onClick={() => !skin.owned && !skins.includes(skin.title) && handleModalOpen(skin.title, skin.cost, skin.icon, () => handlePurchase('skin', skin.cost, skin.title))}
                                     key={idx}>
                                    <div className="boosters-button-detail">
                                        <div className="boosters-button-image">
                                            <img src={`./icons/${skin.icon}`} width="50px" alt="" />
                                        </div>
                                        <div>
                                            <div>
                                                <h4>{skin.title}</h4>
                                            </div>
                                            <div className="boosters-button-data">
                                                {skin.owned || skins.includes(skin.title) ? <h5>You own it</h5> : (
                                                    <>
                                                        <img src="./icons/coin.png" width="20px" alt="" className="m-r-25" />
                                                        <h5>{skin.cost.toLocaleString('en-US')}</h5>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M9 5L14.15 10C14.4237 10.2563 14.6419 10.5659 14.791 10.9099C14.9402 11.2539 15.0171 11.625 15.0171 12C15.0171 12.375 14.9402 12.7458 14.791 13.0898C14.6419 13.4339 14.4237 13.7437 14.15 14L9 19"
                                                stroke="#ffffff" opacity="0.4" strokeWidth="1.5" strokeLinecap="round"
                                                strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        );
    };

    return (
        <div className="boost">
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className="header">
                        <span className="title">Your Share Balance:</span>
                        <div className="total-count">
                            <span className="coin-icon">
                                <img src="/icons/coin.png" width="35px" alt='Coin' />
                            </span>
                            <span className="coin-text">
                                {formatNumber(coins)}
                            </span>
                        </div>
                        <div className="separator"></div>
                    </div>

                    <div className="free-boosts">
                        <div className="free-boosts-title">
                            <h2>Free daily boosters</h2>
                        </div>
                        <div className="free-boosts-buttons">
                            <div
                                className="free-boost-button m-r"
                                onClick={() => handleModalOpen("Turbo")}
                                style={{ opacity: turboUses > 0 ? 1 : 0.5 }}
                            >
                                <div className="free-boosts-detail">
                                    <span className="select-none">Turbo</span>
                                    <span className="available select-none">
                                        {turboUses > 0 ? `${turboUses}/3 available` : `${turboTimer}`}
                                    </span>
                                </div>
                                <div>
                                    <img src="./icons/rocket.png" width="30px" alt="Rocket" />
                                </div>
                            </div>

                            <div
                                className="free-boost-button m-l"
                                onClick={() => handleModalOpen("FullEnergy")}
                                style={{ opacity: fullEnergyUses > 0 ? 1 : 0.5 }}
                            >
                                <div className="free-boosts-detail">
                                    <span className="select-none">Full Energy</span>
                                    <span className="available select-none">
                                        {fullEnergyUses > 0 ? `${fullEnergyUses}/3 available` : `${fullEnergyTimer}`}
                                    </span>
                                </div>
                                <div>
                                    <img src="./icons/thunder.png" width="30px" alt="Thunder" />
                                </div>
                            </div>
                        </div>
                    </div>

                    {isModalOpen && (
                        <div className="modal">
                            <div className="modal-content">
                                <button className="close-button" onClick={closeModal}>×</button>
                                <img src={modalContent.image} alt={modalContent.title} className="modal-image" />
                                <h2>{modalContent.title}</h2>
                                <p>{modalContent.text}</p>
                                <div className="modal-fee">
                                    <img src="./icons/coin.png" width="25px" alt="Coin" />
                                    <span>{modalContent.fee.toLocaleString('en-US')}</span>
                                </div>
                                <button
                                    onClick={modalContent.onClick}
                                    className="modal-button"
                                    disabled={modalContent.status}
                                    style={{ background: modalContent.status ? "Gray" : "" }}
                                >
                                    {modalContent.status ? `${modalContent.timer}` : 'Get it!'}
                                </button>
                            </div>
                        </div>
                    )}

                    <div className="boosters allow-touch">
                        <div className="boosters-title">
                            <h2 className="select-none">Boosters</h2>
                        </div>

                        <div className="boosters-buttons">
                            {renderBoosters(tapLevel, energyLevel, speedLevel, bot)}
                        </div>
                    </div>

                    {/*<div className="buy-skins allow-touch">*/}
                    {/*    <div className="boosters-title" style={{ padding: '0 1.25rem' }}>*/}
                    {/*        <h2 className="select-none">Buy Skins</h2>*/}
                    {/*    </div>*/}
                    {/*    <div className="swiper skinSwiper">*/}
                    {/*        {renderSkins()}*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </>
            )}
        </div>
    );

    function renderBoosters(tapLevel, energyLevel, speedLevel, bot) {
        const boosters = [
            { title: 'Multitap', level: tapLevel, icon: 'point_up.png', maxLevel: 20, prices: tapEnergyPrices },
            { title: 'Energy Limit', level: energyLevel, icon: 'battery.png', maxLevel: 20, prices: tapEnergyPrices },
            { title: 'Recharging Speed', level: speedLevel, icon: 'thunder.png', maxLevel: 5, prices: energySpeedPrices },
            { title: 'Tap Bot', level: bot ? 1 : 0, icon: 'robot_face.png', maxLevel: 1, prices: { 1: 250000 } }
        ];

        return boosters.map((booster, index) => {
            const nextLevel = booster.level + 1;
            const cost = booster.prices[nextLevel] || 0;
            const isMaxed = booster.level >= booster.maxLevel;
            const hasInsufficientBalance = coins < cost;

            return (
                <div
                    className="boosters-button m-b-1"
                    key={index}
                    onClick={() => handleModalOpen(booster.title, cost, booster.icon)}
                    style={{ opacity: hasInsufficientBalance || isMaxed ? 0.5 : 1 }}
                >
                    <div className="boosters-button-detail">
                        <div className="boosters-button-image">
                            <img src={`./icons/${booster.icon}`} alt="" width="50px" />
                        </div>
                        <div>
                            <div>
                                <h4>{booster.title}</h4>
                            </div>
                            <div className="boosters-button-data">
                                <img src="./icons/coin.png" width="20px" alt="" className="m-r-25" />
                                <h5 className="m-r-25">{cost.toLocaleString('en-US')}</h5>
                                <span className="m-r-25">•</span>
                                <p>{booster.level} lvl</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9 5L14.15 10C14.4237 10.2563 14.6419 10.5659 14.791 10.9099C14.9402 11.2539 15.0171 11.625 15.0171 12C15.0171 12.375 14.9402 12.7458 14.791 13.0898C14.6419 13.4339 14.4237 13.7437 14.15 14L9 19"
                                stroke="#ffffff" opacity="0.4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </div>
            );
        });
    }
};

export default Boost;
